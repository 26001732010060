export default {
  title_pt: 'Rotesma - Obra',
  title_en: 'Rotesma - Construction',
  title_es: 'Rotesma - Obra',
  request_quote_pt: 'Solicite um orçamento',
  request_quote_en: 'Solicite um orçamento',
  request_quote_es: 'Solicitar presupuesto',
  request_quote_url_pt: 'orcamento',
  request_quote_url_en: 'request-a-quote',
  request_quote_url_es: 'presupuesto',
  more_pt: 'Mais obras',
  more_en: 'Mais obras',
  more_es: 'Más obras'
}
