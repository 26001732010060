import React, { Fragment, useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'
import Lightbox from 'lightbox-react'
import { ApiHook, Footer, Head, Button, Grid, Link, Slider, Translate, ViewMore } from '@components'
import locale from '@data/locales/construction'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/Construction.module.styl'
import 'lightbox-react/style.css'

function videoId(url: string) {
  let urlParts = url.split('youtu.be')
  if (urlParts.length === 1)
    urlParts = url.split('youtube.com/watch?v=')
  return urlParts[1]
}

export default ({ location: { pathname }, pageContext: _pageContext }) => {
  const { id, meta_cover, video_link, video_cover, keywords, images, city, state, segments, features, ...constructionData } = ApiHook(_pageContext, `constructions/${_pageContext.slug_pt}`)
  const others = ApiHook(
    useStaticQuery(graphql`
      query {
        allSegments {
          nodes {
            name_pt
            constructions {
              title_pt
              title_en
              title_es
              slug_pt
              slug_en
              slug_es
            }
          }
        }
      }
    `).allSegments.nodes,
    'segments?max=9999',
    array => array.filter(({ name_pt }) => name_pt === segments[0]?.name_pt)[0]?.constructions.filter(({ title_pt }) => title_pt !== constructionData.title_pt)
  )
  const [zoomObj, setZoomObj] = useState<{ index: number, items?: Array<string> }>({ index: 0 })
  const [slide, setSlide] = useState(0)
  const videoRef = useRef<YouTube>()
  const [playing, setPlaying] = useState(false)
  const [lastSlide, setLastSlide] = useState(1)
  useEffect(() => {
    const onWindowResize = () => {
      if (window.matchMedia('(min-width: 1430px)').matches) setLastSlide(3)
      else if (window.matchMedia('(min-width: 1300px)').matches) setLastSlide(2)
      else if (window.matchMedia('(min-width: 1170px)').matches) setLastSlide(3)
      else if (window.matchMedia('(min-width: 700px)').matches) setLastSlide(2)
      else setLastSlide(1)
    }
    onWindowResize()
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return <>
    {Translate(constructionData, 'title', title => <Head title={`${title} - Rotesma`} path={pathname} image={meta_cover} />)}

    <header className={st.header}>
      <Grid>
        {segments && segments[0] && Translate(segments[0], 'slug', slug => <Link to={slug} prefix="segment">Segmento {Translate(segments[0], 'name')}</Link>)}
        <h1>{Translate(constructionData, 'title')}</h1>
        <p>{city} - {state}</p>
      </Grid>
    </header>

    <section className={st.description} id="construction-description">
      <Grid className={st.grid}>
        <div>
          <p>{Translate(constructionData, 'description')}</p>
          {Translate(locale, 'request_quote_url', url => <Button to={url} className={st.btn}>{Translate(locale, 'request_quote') as unknown as string}</Button>)}
        </div>
        <ul>
          {features.slice(0, 3).map(({ image, ...feature }, key) => <ViewMore key={key} classToggle={st.open}>{image && <img src={image} />}<span>{Translate(feature, 'name')}</span></ViewMore>)}
        </ul>
      </Grid>
    </section>

    <section className={st.mosaic} id="construction-mosaic">
      <Grid>
        {Boolean(video_link && video_cover) && <div className={st.video}>
          <div className={cn(playing && st.hidden)} style={{ backgroundImage: `url(${video_cover})` }} onClick={() => { videoRef.current.getInternalPlayer().playVideo(); setPlaying(true); }}></div>
          <YouTube videoId={videoId(video_link)} ref={videoRef} />
        </div>}
        <div className={st.images}>
          {images.map(({ large, miniature }, key) => <div key={key} style={{ backgroundImage: `url(${large})` }} onClick={() => setZoomObj({ index: key, items: images.map(i => i.zoom) })}>
            <div style={{ backgroundImage: `url(${miniature})` }}></div>
          </div>)}
        </div>
      </Grid>
    </section>

    {Boolean(others && others.length) && <section className={st.more} id="construction-more">
      <Grid>
        <h3>{Translate(locale, 'more')}</h3>
        <div className={st.controls}>
          <button className={cn('icon-arrow', slide === 0 && st.disabled)} onClick={() => setSlide(slide - 1)}></button>
          <button className={cn('icon-arrow', slide >= others.length - lastSlide && st.disabled)} onClick={() => setSlide(slide + 1)}></button>
        </div>
        <Slider slidesToScroll={1} currentSlide={slide} goToSlide={setSlide} lastSlide={others.length - lastSlide} className={st.slider} finite dots>
          {others.map((construction, key) => <Fragment key={key}>{Translate(construction, 'slug', slug => <Link to={slug} prefix="construction" className={st.slide}>
            <h4>{Translate(construction, 'title')}</h4>
            <span className={st.icon}></span>
          </Link>)}</Fragment>)}
        </Slider>
      </Grid>
    </section>}

    <Footer />

    {zoomObj.items && <Lightbox mainSrc={zoomObj.items[zoomObj.index]} onCloseRequest={() => setZoomObj({ index: 0 })}
      prevSrc={zoomObj.items[(zoomObj.index + zoomObj.items.length - 1) % zoomObj.items.length]}
      nextSrc={zoomObj.items[(zoomObj.index + 1) % zoomObj.items.length]}
      onMovePrevRequest={() => setZoomObj({ ...zoomObj, index: (zoomObj.index + zoomObj.items.length - 1) % zoomObj.items.length })}
      onMoveNextRequest={() => setZoomObj({ ...zoomObj, index: (zoomObj.index + 1) % zoomObj.items.length })} />}
  </>
}