// extracted by mini-css-extract-plugin
export var btn = "Construction-module--btn--77f79";
export var controls = "Construction-module--controls--b5ea2";
export var description = "Construction-module--description--26b07";
export var disabled = "Construction-module--disabled--7156a";
export var grid = "Construction-module--grid--3a605";
export var header = "Construction-module--header--7fc88";
export var hidden = "Construction-module--hidden--f22a3";
export var icon = "Construction-module--icon--c0470";
export var images = "Construction-module--images--6a172";
export var more = "Construction-module--more--a171d";
export var mosaic = "Construction-module--mosaic--017ff";
export var open = "Construction-module--open--d2200";
export var slide = "Construction-module--slide--321ea";
export var slider = "Construction-module--slider--37c15";
export var video = "Construction-module--video--974c8";